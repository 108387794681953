html, body{
  padding: 0;
  margin: 0;
}


*{
  font-family: "Roboto","Helvetica","Arial",sans-serif;
}



.subscribeButton {
  text-align: center;
  /*background-color:#673ab7;*/
  margin: 0;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  text-align: center;
    color:#fff !important;
    margin-top:10px;
    text-transform: inherit;
    background: #0077cc !important;
    margin-top:20px !important;
    text-decoration: none !important;
    text-transform: none !important;
}

.subscribeButton:hover {
    color:#a4b6c3 !important;
}


 h2 {
  font-weight: 200;
}

 h2 strong {
  font-weight: 400;
}






/*** HOME ***/


.Home-headerBox {

  /*
  background: -webkit-gradient(linear, left top, right top, from(#0c3df4), to(#02b5ff));
    background: -webkit-linear-gradient(left, #0c3df4 0%, #02b5ff 100%);
    background: -o-linear-gradient(left, #0c3df4 0%, #02b5ff 100%);
    background: linear-gradient(to right, #0c3df4 0%, #02b5ff 100%);
*/
  /*background-image: linear-gradient(#673ab7 1%, #00bcd4);*/
  /*color:#fff;*/
  text-align: center;
}

.Home-headerBox h1 {
  margin: 0;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 300;
  font-size: 4rem;
  line-height: 1.167;
  letter-spacing: -0.01562em;
  text-align: center;
}


.Home-headerBox h2 {
  margin: 0;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 400;
  font-size: 25px;
  line-height: 1.2;
  letter-spacing: -0.00833em;
  text-align: center;
}

.Home-headerBox p {
  margin: 0;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  text-align: center;
}

.Home-headerBox-logo {
  width: 100%;
  max-width: 100px;
}


.Home-headerBox .deviceImage {
 width: 100%;
 max-width: 500px;
}




.Home-features {
  background-color: #0077cc;
  color:#fff;
}





.Home-app-view {
  text-align: center;
}
.Home-app-view-image {
  width: 100%;
  max-width: 200px;
  text-align: center;
}

.Home-app-view-text {
  text-align: center;
  margin-bottom: 20px;
}





.Home-howItWorks {
  background-color: #0077cc;
  color:#fff;
}

.Home-howItWorks h2 {
  
  margin: 0;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 400;
  font-size: 25px;
  line-height: 1.2;
  letter-spacing: -0.00833em;
  text-align: center;
}

.Home-howItWorks .Stepimage {
  width: 100%;
  max-width: 200px;
}










.Home-Features h2 {

  margin: 0;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 400;
  font-size: 25px;
  line-height: 1.2;
  letter-spacing: -0.00833em;
  text-align: center;
}

.Home-Features .deviceImage {
width: 100%;
max-width: 400px;
margin-bottom: 50px;
}










.Home-Progress h2 {

  margin: 0;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 400;
  font-size: 25px;
  line-height: 1.2;
  letter-spacing: -0.00833em;
  text-align: center;
}

.Home-Progress {

}


.progressBar {
width: fit-content;
max-width: 100%;
margin-left: auto;
margin-right: auto;
margin-top: 30px;
}











footer {
  text-align: center;
  text-decoration: none;
  color: #3f3f3f;
  font-size: 12px;
  margin: 10px;
}


footer * {
  text-align: center;
  text-decoration: none;
  color: #3f3f3f;
}